import React from "react";
import { Link, useLocation } from "react-router-dom";
import LinkedinSvg from "../svgs/LinkedinSvg";
import Github from "../svgs/Github";

export const TopHeader = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  return (
    <div className="w-full lg:h-[60px] flex flex-col sm:flex-row justify-between items-center bg-black px-4 py-2">
      <div className="flex  text-[30px]">
        <p className="text-white">
          Gorvin<span className="text-purple-500">W</span>
          <span className="text-white">s</span>
        </p>
      </div>

      <div className="flex flex-row items-center justify-center text-white  gap-[60px] text-[20px]  ">
        <Link
          to="/about"
          className={`transition-all duration-300 transform hover:scale-150 hover:text-purple-500 cursor-pointer ${
            activeLink === "/about" || activeLink === "/"
              ? "text-purple-500"
              : ""
          }`}
        >
          about
        </Link>

        <Link
          to="/portfolio"
          className={`transition-all duration-300 transform hover:scale-150 hover:text-purple-500 cursor-pointer ${
            activeLink === "/portfolio" ? "text-purple-500" : ""
          }`}
        >
          portfolio
        </Link>
        <Link
          to="/contact"
          className={`transition-all duration-300 transform hover:scale-150 hover:text-purple-500 cursor-pointer ${
            activeLink === "/contact" ? "text-purple-500" : ""
          }`}
        >
          contact
        </Link>
      </div>

      <div className="flex items-center justify-center gap-[20px] mt-2 ">
        <a
          href="https://github.com/Gorvintheone"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Github className="w-[30px] h-[40px]  text-white hover:text-purple-500 transition-colors duration-300" />
        </a>
        <a
          href="https://www.linkedin.com/in/bence-harmath-05373132b/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedinSvg className="w-[25px] h-[30px] text-white hover:text-purple-500 transition-colors duration-300" />
        </a>
      </div>
    </div>
  );
};
