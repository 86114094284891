import React from "react";

const Jel1 = ["<p>"];
const Jel2 = ["</p>"];

export const AboutPage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-[#F5F5F5]">
      <div className="flex flex-col  mx-auto px-4 w-full">
        <h1 className="font-bold text-[60px]  lg:ml-[120px] mt-[40px] animate-slideInLeft">
          about
        </h1>
        <p className="font-bold text-[30px] text-purple-500 ml-[20px] animate-slideInLeft">
          {Jel1}
        </p>
        <p className="ml-[60px] font-oswald text-[20px] mt-[20px] animate-slideInLeft">
          My name is Bence Harmat, and I began learning React programming in
          2023 I had been interested in the field for a long time, and
          eventually, life led me to a point where I could finally start
          learning.
        </p>
        <p className="ml-[80px] mt-[20px] font-oswald text-[19px] animate-slideInLeft">
          I previously worked as an office manager at an agricultural company.
          We had many deadline-driven tasks, which taught me how to manage my
          time effectively. I enjoy working in a team, but I am also comfortable
          working independently.
        </p>
        <p className="ml-[100px] mt-[20px] font-oswald text-[20px] font-bold animate-slideInLeft">
          I am familiar with HTML, CSS (including Tailwind), and I use React. I
          know the basics of JavaScript, but I still have more to learn in this
          area.
        </p>
        <p className="font-bold text-[30px] text-purple-500 ml-[200px] animate-slideInLeft">
          {Jel2}
        </p>
      </div>

      <div className="flex flex-grow w-full items-center bg-white justify-center mt-[20px] p-[20px] sm:p-[40px] animate-slideInRight">
        <div className="w-full max-w-[1200px]">
          <img
            className="w-full h-auto rounded-xl"
            src="/assets/iroda.jpeg"
            alt="my-office"
          />
        </div>
      </div>
    </div>
  );
};
