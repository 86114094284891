import React from "react";
import { TheLeatherraft } from "../TheLeatherCraft";
import { TheMentorSite } from "../TheMenorSite";
import { Portfolio } from "../Portfolio";

export const PortfolioPage = () => {
  return (
    <div>
      <Portfolio />
      <TheLeatherraft />
      <TheMentorSite />
    </div>
  );
};
