import React from "react";

export const Portfolio = () => {
  return (
    <div className="flex flex-col bg-white">
      <div>
        <h1 className="font-bold text-[60px] ml-[120px] mt-[40px] animate-slideInLeft">
          portfolio
        </h1>
        <p className="ml-[60px] font-oswald text-[20px] mt-[20px] font-bold pr-[5px] animate-slideInLeft">
          I am currently working on two projects.
        </p>
        <p className="ml-[80px] mt-[20px] font-oswald text-[19px] animate-slideInLeft">
          - One is a custom order for a Hungarian leather craftsman,
        </p>
        <p className="ml-[80px] mt-[20px] font-oswald text-[20px] animate-slideInLeft">
          - and the other is a project led by my mentor, where we are enhancing
          and expanding our knowledge of JavaScript and other
        </p>
      </div>
    </div>
  );
};
