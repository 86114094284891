import React from "react";
import { Image } from "antd";

const photosForComforty = [
  { id: 1, Photo: "./assets/comforty1.png" },
  { id: 2, Photo: "./assets/comforty2.png" },
  { id: 3, Photo: "./assets/comforty3.png" },
];

export const TheMentorSite = () => {
  return (
    <div className="bg-white border-t ] p-[40px] border-gray-300 animate-slideInRight">
      <div className="font-oswald font-bold text-[30px] mb-[20px] text-center md:text-left">
        <h2>The Mentor Site:</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-[15px] justify-center items-center">
        {photosForComforty.map((item, index) => (
          <div key={index} className="p-[15px] flex justify-center">
            <Image className="w-[200px]" src={item.Photo} alt="web-fotos" />
          </div>
        ))}
      </div>
    </div>
  );
};
